<template>
  <div class="overflow-hidden">
    <HeroV2 :visible.sync="visible" class="pt-28">
      <XrayWrapperConsumer />
    </HeroV2>

    <main>
      <b-container class="mt-16 mt-lg-24">
        <b-row>
          <b-col lg="12">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <h2
                class="
                  font-weight-black
                  text-center
                  tracking-tight
                  text-3xl text-md-5xl text-xl-6xl
                  font-realist
                  leading-none
                  mb-0
                  text-blue-500
                "
              >
                You found the perfect car, but you need more buying assurance.
              </h2>
            </div>
          </b-col>
          <b-col
            lg="10"
            class="d-flex mx-auto mt-xl-10 mt-3 align-items-center"
          >
            <div>
              <p class="text-md-3xl text-sm-xl text-lg text-center mb-0">
                Our expert technicians perform the most comprehensive cosmetic
                and structural inspection available. The inspection findings are
                populated into an easy-to-read report, giving you the tools to
                buy your next vehicle in complete confidence.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-18 mt-sm-32 mt-xl-56">
        <b-row>
          <b-col lg="6" order-lg="2">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <img src="@/assets/img/consumer.jpg" class="section-asset" />
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-lg-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Peace of Mind Delivered
                </h2>
              </div>
              <p class="text-md text-sm-lg text-md-xl">
                When buying from any True360 Certified Dealer, you will have
                peace of mind when purchasing your next vehicle. You can view
                prior reports on inspected vehicles while shopping online or
                in-person.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="my-18 my-sm-32 my-xl-56">
        <b-row>
          <b-col lg="6">
            <div class="mb-8 mb-md-12 mb-lg-0">
              <img
                class="img-fluid"
                :src="require('@/assets/img/true360-report-spread.png')"
              />
              <!-- <Report /> -->
            </div>
          </b-col>
          <b-col lg="6" class="d-flex align-items-center">
            <div>
              <div class="mb-8">
                <h2
                  class="
                    font-weight-black
                    text-center text-lg-left
                    tracking-tight
                    text-3xl text-md-5xl text-xl-6xl
                    font-realist
                    leading-none
                    mb-0
                    text-blue-500
                  "
                >
                  Complete Confidence
                </h2>
              </div>
              <p class="text-md text-sm-lg text-md-xl">
                With a structural inspection and overall cosmetic body score in
                each report, True360 provides the quality information needed to
                help confirm your final vehicle choice.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import HeroV2 from "@/components/global/HeroV2";
import XrayWrapperConsumer from "@/components/XrayWrapperConsumer";

export default {
  name: "CommercialPage",
  components: {
    HeroV2,
    XrayWrapperConsumer
  },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      visible: true,
      fleetIsVisible: false
    };
  }
};
</script>
<style lang="scss" scoped>
.hero-container {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(14, 103, 156, 1) 0%,
    rgba(92, 173, 218, 1) 50%,
    rgba(204, 220, 229, 1) 100%
  );

  height: 300px;
  @media (min-width: 415px) {
    height: 450px;
  }
  @media (min-width: 767px) {
    height: 500px;
  }
  @media (min-width: 992px) {
    height: 650px;
  }
  @media (min-width: 1367px) {
    height: 700px;
  }
  @media (min-width: 1719px) {
    height: calc(1382 / 2000 * 1000vw);
    max-height: 800px;
  }
}
.background {
  height: 100%;
  width: 100%;
  background-image: url("../assets/img/hero-backdrop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.art-container {
  position: absolute;
  bottom: -100px;
}
.text-container {
  margin: 6vw auto 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  padding: 0 5vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0 5vw;
  }

  @media (min-width: 1200px) {
    margin-top: 80px;
  }
  @media (min-width: 1719px) {
    margin-top: 100px;
  }

  h1 {
    text-shadow: 0.25vw 0.25vw 1vw rgba(#004e7d, 0.13);
  }

  span {
    display: block;
    font-size: 1.75vw;
    line-height: 1.3;
    margin: auto;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 13px;
      max-width: 100%;
    }
    max-width: 70%;
  }
}

.section-asset {
  @media (max-width: 991px) {
    width: 100%;
  }
}

.icon {
  // width: 100px;
  // max-width: 100px;
  height: auto;
  max-height: initial;
}

.icon-content {
  max-width: 90%;
  padding-left: 1rem;
  margin-bottom: 1rem;

  p {
    padding-right: 1rem;
  }
  ul {
    padding-left: 1.5rem;
  }
}
</style>
